export async function setTargetQty(apiService, type, data, id) {
    const result = await apiService.setTargetQty(type, data, id);
    return result;
}

export async function confirmTargetQty(apiService, type, data, id) {
    const result = await apiService.confirmTargetQty(type, data, id);
    return result;
}

export async function cancelTargetQty(apiService, type, data, id) {
    const result = await apiService.cancelTargetQty(type, data, id);
    return result;
}

export function getPendingTargetQty(apiService) {
    return apiService.getPendingTargetQty();
}

export function getQty(apiService) {
    return apiService.getQty();
}

// ---------- rebalance controller
export function enableRebalance(apiService) {
    return apiService.enableRebalance();
}

export function submitTriggerPeriod(apiService, data) {
    return apiService.postToCore("/v3/rate-trigger-period", data);
}

export function getCurrentTriggerPeriod(apiService) {
    return apiService.getFromCore("/v3/rate-trigger-period");
}

export function submitMarginLevelThreshold(apiService, data) {
    return apiService.postToCore("/v3/margin-level-threshold", data);
}

export function getCurrentMarginLevelThreshold(apiService) {
    return apiService.getFromCore("/v3/margin-level-threshold");
}

export function submitPerpetualUtilizationThreshold(apiService, data) {
    return apiService.postToCore("/v3/perpetual-utilization-threshold", data);
}

export function getCurrentPerpetualUtilizationThreshold(apiService) {
    return apiService.getFromCore("/v3/perpetual-utilization-threshold");
}


export function submitVolatilityAdjustment(apiService, data) {
    return apiService.postToCore("/v3/volatility-adjustment", data);
}

export function getVolatilityAdjustment(apiService) {
    return apiService.getFromCore("/v3/volatility-adjustment");
}

export function holdRebalance(apiService) {
    return apiService.holdRebalance();
}

export function getRebalanceStatus(apiService) {
    return apiService.getRebalanceStatus();
}

export function triggerRebalance(apiService, data) {
    return apiService.postToCore("/v3/trigger-rebalance", data)
}

export function getMarginStatus(apiService) {
    return apiService.getFromCore("/v3/margin-config")
}

export function setMarginStatus(apiService, enable) {
    return apiService.postToCore("/v3/margin-config", JSON.stringify({ enable }));
}

export function getMarginTradeStatus(apiService) {
    return apiService.getFromCore("/v3/margin-trade-config")
}

export function setMarginTradeStatus(apiService, enable) {
    return apiService.postToCore("/v3/margin-trade-config", JSON.stringify({ enable }));
}


export function getPredictorConfigStatus(apiService) {
    return apiService.getFromCore("/v3/predictor-config")
}

export function setPredictorConfigStatus(apiService, enable) {
    return apiService.postToCore("/v3/predictor-config", JSON.stringify({ enable }));
}


export function getPerpetualStatus(apiService) {
    return apiService.getFromCore("/v3/perpetual-config")
}

export function setPerpetualStatus(apiService, enable) {
    return apiService.postToCore("/v3/perpetual-config", JSON.stringify({ enable }));
}

// ----------- set rate controller
export function enableSetRate(apiService, selector) {
    return apiService.enableSetRate(selector);
}

export function disableSetRate(apiService, selector) {
    return apiService.disableSetRate(selector);
}

export function getSetRateStatus(apiService, selector) {
    return apiService.getSetRateStatus(selector);
}

// ------------pwis
export function getPwisEquation(apiService) {
    return apiService.getFromCore("/v3/setting-change-pwis");
}

export function setPwisEquation(apiService, data) {
    return apiService.postToCore("/v3/setting-change-pwis", data);
}

export function confirmPwisEquation(apiService, id) {
    return apiService.putToCore(`/v3/setting-change-pwis/${id}`);
}

export function cancelPwisEquation(apiService, id) {
    return apiService.deleteToCore(`/v3/setting-change-pwis/${id}`);
}

export function getPendingPwisEquation(apiService) {
    return apiService.getFromCore("/v3/setting-change-pwis");
}

//--------------- exchange status----------------------

export async function getExchangeStatus(apiService) {
    const result = await apiService.getExchangeStatus();
    return result;
}

export async function getPendingExchangeUpdate(apiService) {
    return apiService.getFromCore("/v3/setting-change-update-exchange");
}

export function updateExchangeStatus(apiService, exId, data) {
    return apiService.updateExchangeStatus(exId, data);
}

export function confirmUpdateExchange(apiService, dataId) {
    return apiService.confirmUpdateExchange(dataId);
}

export function deleteUpdateExchange(apiService, dataId) {
    return apiService.deleteUpdateExchange(dataId);
}

// getListTokens return tokens that asset_type=default.
export function getListTokens(apiService) {
    let include_disabled = JSON.parse(localStorage.getItem("configuration-show-all-assets") || false)
    return apiService.getFromCore("/v3/asset?include_disabled=" + include_disabled);
}

// getAllTokens returns all tokens (asset_type=default & unlisted)
export function getAllTokens(apiService) {
    let include_disabled = JSON.parse(localStorage.getItem("configuration-show-all-assets") || false)
    return apiService.getFromCore("/v3/asset?asset_type=all&include_disabled=" + include_disabled);
}

export function getTokenPrice(apiService, queryString) {
    return apiService.getFromCore(`/0x/price?${queryString}`);
}

export function getAllIntegrations(apiService) {
    return apiService.getFromCore("/0x/allow-integration");
}

export function updateIntegrations(apiService, data) {
    return apiService.postToCore("/0x/allow-integration", JSON.stringify(data));
}

export function getListExchange(apiService) {
    return apiService.getFromCore("/v3/exchange");
}

export function getPendingAssetExchange(apiService) {
    return apiService.getFromCore("/v3/create-asset-exchange");
}
export function getPendingListToken(apiService) {
    return apiService.getFromCore("/v3/create-asset");
}

export function submitListToken(apiService, value) {
    return apiService.postToCore("/v3/create-asset", value);
}

export function createAssetExchange(apiService, value) {
    return apiService.postToCore("/v3/create-asset-exchange", value);
}

export function settingChange(apiService, value) {
    return apiService.postToCore("/v3/setting-change-main", value);
}

export function getPendingSettingChange(apiService) {
    return apiService.getFromCore("/v3/setting-change-main");
}

export function confirmPendingSettingChange(apiService, id) {
    return apiService.putToCore(`/v3/setting-change-main/${id}`, null);
}

export function cancelConfirmPendingSettingChange(apiService, id) {
    return apiService.deleteToCore(`/v3/disapprove-setting-change/${id}`, null);
}

export function rejectPendingSettingChange(apiService, id) {
    return apiService.deleteToCore(`/v3/setting-change-main/${id}`, null);
}

export function confirmListTokens(apiService, id) {
    return apiService.putToCore(`/v3/create-asset/${id}`, null);
}
export function confirmCreateAssetExchange(apiService, id) {
    return apiService.putToCore(`/v3/create-asset-exchange/${id}`, null);
}
export function rejectCreateAssetExchange(apiService, id) {
    return apiService.deleteToCore(`/v3/create-asset-exchange/${id}`, null);
}

export function rejectListTokens(apiService, id) {
    return apiService.deleteToCore(`/v3/create-asset/${id}`, null);
}


export function enableBtc(apiService) {
    return apiService.postToCore("set-fetcher-configuration", { btc: true });
}

export function disableBtc(apiService) {
    return apiService.postToCore("set-fetcher-configuration", { btc: false });
}

// ------------- feed
export function setExchangeStatus(apiService, exchangeId, isDisable) {
    return apiService.putToCore(
        "/v3/set-exchange-enabled/" + exchangeId,
        JSON.stringify({
            disable: isDisable,
        })
    );
}

export function submitHighLowGas(apiService, high, low) {
    const data = JSON.stringify({
        high,
        low,
    });
    return apiService.postToCore(`/v3/gas-threshold`, data);
}

export function getGasConfig(apiService) {
    return apiService.getFromCore("/v3/gas-threshold");
}

export function submitGasSource(apiService, selectedGasSource) {
    const data = JSON.stringify({
        name: selectedGasSource.value,
    });
    return apiService.postToCore(`/v3/gas-source`, data);
}

export function getGasSource(apiService) {
    return apiService.getFromCore("/v3/gas-source");
}

// --------------- alert settings
export function getAlertSettings(apiService) {
    return apiService.getFromCore("/v3/alert-settings");
}

export function updateAlertSettings(apiService, name, value) {
    return apiService.patchToCore("/v3/alert-setting", JSON.stringify({ name, value }));
}

// -------------- tradelogs
export function getTradelogs(apiService, addr, from, to, limit, offset) {
    const host = "https://trading-gateway.kyberengineering.io"
    return apiService.getFromCore(`/xmonitor/v2/data/onchains?makers=${addr}&takers=${addr
        }&from=${from}&to=${to}&limit=${limit}&offset=${offset}&include_fee=true`, host);
}

export function getTokenRate(apiService, base, quote) {
    const host = "https://trading-gateway.kyberengineering.io"
    return apiService.getFromCore(`/mark-to-market/rate?base=${base}&quote=${quote}`, host);
}

export function marginReload(apiService, id) {
    return apiService.postToCore("/cex-data/binance/margin-reload/" + id);
}

export function marginTransfer(apiService, id, data) {
    const params = new URLSearchParams(data);
    return apiService.postToCore(`/cex-data/binance/margin-transfer/${id}?${params.toString()}`);
}

export function marginRepay(apiService, id, data) {
    const params = new URLSearchParams(data);
    return apiService.postToCore(`/cex-data/binance/margin-repay/${id}?${params.toString()}`);
}
