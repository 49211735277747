import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { withAlert } from "react-alert";
import Masonry from "react-masonry-component";
import { JsonEditor } from "jsoneditor-react";

import { TableData } from "../../Share";
import { NewBtn, EditBtn, CancelBtn, SaveBtn, ConfirmBtn, RejectBtn, AdvanceBtn, BackBtn } from "../Share/btn";

const ConfigTable = (props) => {
    const apiService = useSelector((store) => store.global.apiService);
    const hostData = useSelector((store) => store.global.hostData)
    const tokens = useSelector((store) => store.global.tokens);

    const [mapRawData, set_mapRawData] = useState({})
    const [input, set_input] = useState([])
    const [hasPending, set_hasPending] = useState(false)
    const [pendingTarget, set_pendingTarget] = useState({})
    const [targetDiff, set_targetDiff] = useState({})
    const [isEdit, set_isEdit] = useState(false)
    const [showEditor, set_showEditor] = useState(false)

    const jsonEditorRef = useRef(null)

    useEffect(() => cook(), [])

    const cook = async () => {
        const res = await apiService.getFromCore("/v3/all-integration");
        if (!res.success) {
            props.alert.error(result.reason || result.error);
            return
        }
        const newInput = []
        const newMapRawData = {}

        res.data?.forEach(c => {
            newInput.push({ ...c })
            newMapRawData[c.id] = c
        })
        set_input(newInput)
        set_mapRawData(newMapRawData)

        // get pending
        const pending = await apiService.getFromCore(props.pendingUrl);
        if (pending?.success && pending?.data?.length) {
            const parsedData = pending.data[0];
            set_hasPending(true)
            set_pendingTarget(parsedData)
            const newTargetDiff = {};
            let pendingInput = [...newInput]
            parsedData.change_list.forEach((t) => {
                switch (t.type) {
                    case "create_integration":
                        const newId = new Date().getTime();
                        pendingInput = [{ ...t.data, is_new: true, id: newId }, ...pendingInput]
                        break
                    case "update_integration":
                        newTargetDiff[t.data.id] = t.data;
                        break
                    case "delete_integration":
                        pendingInput = pendingInput.map(p => {
                            if (p.id === t.data.id) return { ...p, is_delete: true }
                            return p
                        })
                        break
                }
            });
            set_input(pendingInput)
            set_targetDiff(newTargetDiff)

        } else {
            set_hasPending(false)
            set_pendingTarget({})
        }
    }

    const confirmPending = async () => {
        if (pendingTarget?.id) {
            const result = await apiService.putToCore(props.pendingUrl + "/" + pendingTarget.id);

            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to confirm pending!");
            cook()
        }
    };

    const cancelPending = async () => {
        if (pendingTarget?.id) {
            const result = await apiService.deleteToCore(props.pendingUrl + "/" + pendingTarget.id);

            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to cancel pending!");

            cook()
        }
    };

    const onChangeInput = (field, value, rowId) => {
        set_input(input.map(row => {
            if (row.id === rowId) {
                row[field] = value
                return row
            }
            return row
        }))
    }

    const parsedInput = () => {
        const dataArray = [];
        const inputMap = {}
        input.forEach(t => {
            if (!t.is_delete) {
                inputMap[t.id] = true

                if (!mapRawData[t.id])
                    dataArray.push({
                        type: "create_integration",
                        data: {
                            name: t.name,
                            sample_size: +t.sample_size,
                            prices_interval_second: +t.prices_interval_second,
                            volatility_period_second: +t.volatility_period_second,
                            target_adjust: +t.target_adjust,
                            weight: +t.weight,
                            ddof: +t.ddof,
                            ttl: +t.ttl,
                            level_volatility_factor: +t.level_volatility_factor,
                            max_trade_eth: +t.max_trade_eth,
                            price_bps_delta: +t.price_bps_delta,
                            quote_bps_delta: +t.quote_bps_delta,
                            quote_delay_millis: +t.quote_delay_millis,
                            quote_volatility_factor: +t.quote_volatility_factor,
                        },
                    });
                else if (Object.entries(mapRawData[t.id]).toString() !== Object.entries(t).toString()) {
                    const data = {
                        id: +t.id,
                        name: t.name,
                        sample_size: +t.sample_size,
                        prices_interval_second: +t.prices_interval_second,
                        volatility_period_second: +t.volatility_period_second,
                        target_adjust: +t.target_adjust,
                        weight: +t.weight,
                        ddof: +t.ddof,
                        ttl: +t.ttl,
                        level_volatility_factor: +t.level_volatility_factor,
                        max_trade_eth: +t.max_trade_eth,
                        price_bps_delta: +t.price_bps_delta,
                        quote_bps_delta: +t.quote_bps_delta,
                        quote_delay_millis: +t.quote_delay_millis,
                        quote_volatility_factor: +t.quote_volatility_factor,
                    };
                    dataArray.push({ type: "update_integration", data });
                }
            }
        })

        Object.keys(mapRawData).forEach(id => {
            if (!inputMap[id])
                dataArray.push({
                    type: "delete_integration",
                    data: { id: +id }
                });
        })

        return dataArray;
    }

    const submit = async () => {
        if (showEditor) {
            try {
                jsonEditorRef?.current?.jsonEditor?.get()
            } catch (e) {
                props.alert.error("Can not save, please check the data!");
                return
            }
        }

        const data = JSON.stringify({ change_list: parsedInput() });
        const result = await apiService.postToCore(props.pendingUrl, data);

        if (result.success) {
            props.alert.success("success");
            set_isEdit(false)
            set_showEditor(false)
            cook()
        }
        else props.alert.error(result.reason || result.error || "Failed to cancel pending!");
    };

    const onButtonClick = (btnName) => {
        switch (btnName) {
            case "new":
                const id = new Date().getTime()
                set_input([{
                    id,
                    name: "Integration " + Math.floor(id / 1000),
                    sample_size: 21,
                    prices_interval_second: 12,
                    volatility_period_second: 36,
                    target_adjust: 1.2,
                    weight: 0.95,
                    ddof: 1,
                    ttl: 0,
                    level_volatility_factor: 0,
                    max_trade_eth: 0,
                    price_bps_delta: 0,
                    quote_bps_delta: 0,
                    quote_delay_millis: 0,
                    quote_volatility_factor: 0,
                    is_new: true
                }, ...input])
                break;
            case "edit":
                set_isEdit(true)
                break;
            case "cancel":
                cook()
                set_isEdit(false)
                break;
            case "save":
                submit();
                break;
            case "confirm":
                confirmPending();
                break;
            case "reject":
                cancelPending();
                break;
            case "advance":
                set_showEditor(true)
                break;
            case "back":
                set_showEditor(false)
                break;
        }
    }
    const newBtn = <NewBtn clickFunc={() => onButtonClick("new")} />
    const editBtn = <EditBtn clickFunc={() => onButtonClick("edit")} />
    const cancelBtn = <CancelBtn clickFunc={() => onButtonClick("cancel")} />
    const saveBtn = <SaveBtn clickFunc={() => onButtonClick("save")} />
    const confirmBtn = <ConfirmBtn clickFunc={() => onButtonClick("confirm")} />
    const rejectBtn = <RejectBtn clickFunc={() => onButtonClick("reject")} />
    const advanceBtn = <AdvanceBtn clickFunc={() => onButtonClick("advance")} />
    const backBtn = <BackBtn clickFunc={() => onButtonClick("back")} />

    const btnElements = () => {
        if (hasPending) {
            if (pendingTarget?.proposer && hostData?.CORE.id == pendingTarget.proposer)
                return [rejectBtn]
            return [confirmBtn, rejectBtn]
        }
        return isEdit ? [advanceBtn, newBtn, saveBtn, cancelBtn] : [editBtn]
    }

    const options = Object.values(tokens).map((asset) => {
        return { value: asset.id, label: asset.symbol };
    })

    const renderEditable = (cell) => {
        const updateField = cell.column.id;
        return (
            <div
                contentEditable={true}
                suppressContentEditableWarning
                onBlur={(e) => {
                    onChangeInput(updateField, e.target.innerHTML, cell.original.id);
                }}
                onPaste={e => {
                    e.preventDefault()
                    document.execCommand('insertText', false, e.clipboardData.getData('text/plain'))
                }}
                dangerouslySetInnerHTML={{
                    __html: cell.original[updateField]
                }}
            />
        );
    };

    const cellFunc = (row, field_name, field) => {
        const newVal = targetDiff[row.original.id]?.[field_name]
        const hasChange = hasPending && newVal !== undefined && newVal !== null && newVal != row.value

        if (hasChange) {
            return <div>
                <div style={{ border: "1px dashed blue" }}>{newVal}</div>
                <p className="text-center mb-0"><span className="text-warning">Old: {field.cell ? field.cell(row) : row.value}</span></p>
            </div>
        }
        return <div >{field.cell ? field.cell(row) : row.value}</div>
    }

    const columns = []
    Object.keys(props.fields).forEach((field) => {
        const col = props.fields[field]
        if (col.children) {
            columns.push({
                Header: col.title,
                id: field,

                columns: Object.keys(col.children).map(child => {
                    return {
                        Header: col.children[child].title,
                        id: child,
                        accessor: child,
                        Cell: isEdit ? renderEditable : (cell => cellFunc(cell, child, col.children[child]))
                    }
                })
            })

        } else {
            columns.push({
                Header: col.title,
                id: field,
                accessor: field,
                Cell: isEdit ? renderEditable : (cell => cellFunc(cell, field, col))
            })
        }
    });

    if (isEdit) {
        columns.push({
            Header: "Delete",
            id: "delete",
            Cell: cell => <i className="fa fa-times fa-lg text-danger pointer" aria-hidden="true"
                onClick={e => onDeleteInput(cell.original)}
            />
        })
    }

    const onDeleteInput = (row) => {
        if (row.is_new) set_input(input?.filter(i => i.id != row.id))
        else
            set_input(input?.map(i => {
                if (i.id === row.id)
                    return { ...i, is_delete: true }
                return i
            }))
    }


    const rowClassName = (state, row) => {
        switch (true) {
            case row.original.is_new:
                return "bg-is-new";
            case row.original.is_delete:
                return "bg-is-delete";
        }
    };

    const masonryOptions = {
        transitionDuration: 0,
        percentPosition: true,
        columnWidth: props.columnWidth || 1,
        enableResizableChildren: true,
    };

    const onchangeJsonEditor = (value) => {
        set_input(value)
    }

    return (
        <div >
            <div className="col-sm-12 p-0" style={{ height: "100vh" }}>
                <div className="panel-header">
                    <div className="panel-title text-dark p-0">
                        Integration settings
                        {showEditor && <span>{backBtn} {saveBtn}
                        </span>}
                    </div>
                    <Masonry options={masonryOptions}>
                        {
                            showEditor ? (
                                <JsonEditor
                                    className="w-100" value={input} mode="code" ref={jsonEditorRef}
                                    allowedModes={["tree", "view", "form", "code", "text"]}
                                    htmlElementProps={{ style: { height: "calc(100vh - 100px)", width: "100%" } }}
                                    onChange={(value) => onchangeJsonEditor(value)}
                                />
                            ) :
                                <div className="col-sm-12 p-0 mt-3 table-group">
                                    <TableData
                                        style={{ maxHeight: "calc(100vh - 140px)" }}
                                        data={input}
                                        buttonElements={btnElements()} columns={columns}
                                        rowClassName={rowClassName}
                                        overWriteProps={{
                                            minRows: 3,
                                        }}
                                    />
                                </div>
                        }
                    </Masonry>
                </div>
            </div>
        </div >
    );
}

export default withAlert()(ConfigTable);
