import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { withAlert } from "react-alert";
import Masonry from "react-masonry-component";
import { JsonEditor } from "jsoneditor-react";
import Select from "react-select";

import { TableData, Switch } from "../../Share";
import { NewBtn, EditBtn, CancelBtn, SaveBtn, ConfirmBtn, RejectBtn, AdvanceBtn, BackBtn } from "../Share/btn";

const capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const ConfigTable = (props) => {
    const apiService = useSelector((store) => store.global.apiService);
    const hostData = useSelector((store) => store.global.hostData)
    const tokens = useSelector((store) => store.global.tokens);

    const [mapRawData, set_mapRawData] = useState({})
    const [input, set_input] = useState([])
    const [hasPending, set_hasPending] = useState(false)
    const [pendingTarget, set_pendingTarget] = useState({})
    const [targetDiff, set_targetDiff] = useState({})
    const [isEdit, set_isEdit] = useState(false)
    const [showEditor, set_showEditor] = useState(false)

    const [searchIntegration, set_searchIntegration] = useState([])
    const [searchBase, set_searchBase] = useState([])
    const [searchQuote, set_searchQuote] = useState([])
    const [searchChange, set_searchChange] = useState([])

    const [integrations, setIntegrations] = useState([])

    const jsonEditorRef = useRef(null)

    useEffect(() => cook(), [])

    const cook = async () => {
        const res = await apiService.getFromCore("/v3/all-pair-rfq");
        if (!res.success) {
            props.alert.error(result.reason || result.error);
            return
        }

        const newInput = []
        const newMapRawData = {}
        res.data.forEach(pair => {
            if (tokens[pair.base_asset_id] && tokens[pair.quote_asset_id]) {
                newInput.push({ ...pair })
                newMapRawData[pair.id] = pair
            }
        })
        set_input(newInput)
        set_mapRawData(newMapRawData)

        const igSet = new Set()
        newInput.forEach(i => {
            igSet.add(i.integration)
        })
        const igs = []
        igSet.forEach(i => igs.push(i))
        igs.sort((a, b) => a > b ? 1 : -1);
        setIntegrations(igs)

        // get pending
        const pending = await apiService.getFromCore(props.pendingUrl);
        if (pending?.success && pending?.data?.length) {
            const parsedData = pending.data[0];
            set_hasPending(true)
            set_pendingTarget(parsedData)
            const newTargetDiff = {};
            let pendingInput = [...newInput]
            parsedData.change_list.forEach((t) => {
                switch (t.type) {
                    case "create_pair_rfq_params":
                        const newId = new Date().getTime();
                        pendingInput = [{ ...t.data, is_new: true, id: newId }, ...pendingInput]
                        break
                    case "update_pair_rfq_params":
                        newTargetDiff[t.data.id] = t.data;
                        break
                    case "delete_pair_rfq_params":
                        pendingInput = pendingInput.map(p => {
                            if (p.id === t.data.id) return { ...p, is_delete: true }
                            return p
                        })
                        break
                }
            });
            set_input(pendingInput)
            set_targetDiff(newTargetDiff)

        } else {
            set_hasPending(false)
            set_pendingTarget({})
        }
    }

    const filterInput = () => {
        let filterData = input
        if (searchIntegration?.length) {
            const searches = searchIntegration.map((s) => s.value);
            filterData = filterData.filter(t => searches.includes(t.integration))
        }

        if (searchBase?.length) {
            const searches = searchBase.map((s) => s.value);
            filterData = filterData.filter(t => searches.includes(t.base_asset_id))
        }

        if (searchQuote?.length) {
            const searches = searchQuote.map((s) => s.value);
            filterData = filterData.filter(t => searches.includes(t.quote_asset_id))
        }

        if (searchChange?.length) {
            const searchChangeMap = {}
            searchChange.forEach(s => searchChangeMap[s.value] = true)
            filterData = filterData.filter(t => (searchChangeMap["new"] && t.is_new) ||
                (searchChangeMap["delete"] && t.is_delete) ||
                (searchChangeMap["change"] && targetDiff[t.id]) ||
                (searchChangeMap["unchange"] && !(t.is_new || t.is_delete || targetDiff[t.id]))
            )
        }

        return filterData
    }

    const confirmPending = async () => {
        if (pendingTarget?.id) {
            const result = await apiService.putToCore(props.pendingUrl + "/" + pendingTarget.id);

            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to confirm pending!");
            cook()
        }
    };

    const cancelPending = async () => {
        if (pendingTarget?.id) {
            const result = await apiService.deleteToCore(props.pendingUrl + "/" + pendingTarget.id);

            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to cancel pending!");

            cook()
        }
    };

    const onChangeInput = (field, value, rowId) => {
        set_input(input?.map(row => {
            if (row.id === rowId) {
                row[field] = value
                return row
            }
            return row
        }))
    }

    const onDeleteInput = (row) => {
        if (row.is_new) set_input(input?.filter(i => i.id != row.id))
        else
            set_input(input?.map(i => {
                if (i.id === row.id)
                    return { ...i, is_delete: true }
                return i
            }))
    }

    const parsedInput = () => {
        const dataArray = [];
        const inputMap = {}
        input?.forEach(t => {
            if (!t.is_delete) {
                inputMap[t.id] = true

                if (!mapRawData[t.id])
                    dataArray.push({
                        type: "create_pair_rfq_params",
                        data: {
                            base_asset_id: +t.base_asset_id,
                            quote_asset_id: +t.quote_asset_id,
                            integration: t.integration,
                            enabled: t.enabled,
                            ask_offset: +t.ask_offset,
                            bid_offset: +t.bid_offset,
                            sanity_accept_diff: +t.sanity_accept_diff,
                            amm_adjust_diff: +t.amm_adjust_diff,
                            price_type: t.price_type,
                            min_profit: +t.min_profit,
                            swap_enabled: t.swap_enabled,
                        },
                    });
                else if (Object.entries(mapRawData[t.id]).toString() !== Object.entries(t).toString()) {
                    const data = { id: +t.id };
                    ["base_asset_id", "quote_asset_id", "ask_offset", "bid_offset", "sanity_accept_diff", "amm_adjust_diff", "min_profit"].forEach(s => {
                        if (mapRawData[t.id][s] !== +t[s])
                            data[s] = +t[s]
                    });
                    ["integration", "enabled", "swap_enabled", "price_type"].forEach(s => {
                        if (mapRawData[t.id][s] !== t[s])
                            data[s] = t[s]
                    });
                    dataArray.push({ type: "update_pair_rfq_params", data });
                }
            }
        })

        Object.keys(mapRawData).forEach(id => {
            if (!inputMap[id])
                dataArray.push({
                    type: "delete_pair_rfq_params",
                    data: { id: +id }
                });
        })

        return dataArray;
    }

    const submit = async () => {
        if (showEditor) {
            try {
                jsonEditorRef?.current?.jsonEditor?.get()
            } catch (e) {
                props.alert.error("Can not save, please check the data!");
                return
            }
        }

        const data = JSON.stringify({ change_list: parsedInput() });
        const result = await apiService.postToCore(props.pendingUrl, data);

        if (result.success) {
            props.alert.success("success");
            set_isEdit(false)
            set_showEditor(false)
            cook()
        }
        else props.alert.error(result.reason || result.error || "Failed to cancel pending!");
    };

    const onButtonClick = (btnName) => {
        switch (btnName) {
            case "new":
                set_input([{
                    id: new Date().getTime(),
                    base_asset_id: searchBase?.length ? searchBase[0].value : 1,
                    quote_asset_id: searchQuote?.length ? searchQuote[0].value : 1,
                    integration: searchIntegration?.length
                        ? searchIntegration[0].value
                        : integrations?.length ? integrations[0] : "1inch",
                    enabled: true,
                    ask_offset: 0,
                    bid_offset: 0,
                    sanity_accept_diff: 0,
                    amm_adjust_diff: 0,
                    price_type: "",
                    min_profit: 0,
                    swap_enabled: true,
                    is_new: true,
                }, ...input])
                break;
            case "edit":
                set_isEdit(true)
                break;
            case "cancel":
                cook()
                set_isEdit(false)
                break;
            case "save":
                submit();
                break;
            case "confirm":
                confirmPending();
                break;
            case "reject":
                cancelPending();
                break;
            case "advance":
                set_showEditor(true)
                break;
            case "back":
                set_showEditor(false)
                break;
        }
    }
    const newBtn = <NewBtn clickFunc={() => onButtonClick("new")} />
    const editBtn = <EditBtn clickFunc={() => onButtonClick("edit")} />
    const cancelBtn = <CancelBtn clickFunc={() => onButtonClick("cancel")} />
    const saveBtn = <SaveBtn clickFunc={() => onButtonClick("save")} />
    const confirmBtn = <ConfirmBtn clickFunc={() => onButtonClick("confirm")} />
    const rejectBtn = <RejectBtn clickFunc={() => onButtonClick("reject")} />
    const advanceBtn = <AdvanceBtn clickFunc={() => onButtonClick("advance")} />
    const backBtn = <BackBtn clickFunc={() => onButtonClick("back")} />

    const btnElements = () => {
        if (hasPending) {
            if (pendingTarget?.proposer && hostData?.CORE.id == pendingTarget.proposer)
                return [rejectBtn]
            return [confirmBtn, rejectBtn]
        }
        return isEdit ? [advanceBtn, newBtn, saveBtn, cancelBtn] : [editBtn]
    }

    const options = Object.values(tokens).map((asset) => {
        return { value: asset.id, label: asset.symbol };
    })

    const renderEditable = (cell) => {
        const updateField = cell.column.id;
        const data = props.fields[updateField]

        if (data.type == "bool")
            return (
                <Switch
                    onChange={e => onChangeInput(updateField, e, cell.original.id)}
                    checked={cell.original[updateField]}
                />
            );

        if (data.type == "select")
            return (
                <select
                    className="custom-select"
                    onChange={(e) => onChangeInput(updateField, e.target.value, cell.original.id)}
                >
                    {integrations?.map((value, id) => (
                        <option value={value} key={id} selected={value == cell.original[updateField]}>
                            {value}
                        </option>
                    ))}
                </select>
            )

        if (data.type == "select_asset") {
            return <Select
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                options={options}
                onChange={e => onChangeInput(updateField, e.value, cell.original.id)}
                value={options.filter(o => o.value == cell.original[updateField])}
            />
        }
        return (
            <div
                contentEditable={!data.const}
                suppressContentEditableWarning
                onBlur={(e) => {
                    onChangeInput(updateField, e.target.innerHTML, cell.original.id);
                }}
                dangerouslySetInnerHTML={{
                    __html: cell.original[updateField]
                }}
                onPaste={e => {
                    e.preventDefault()
                    document.execCommand('insertText', false, e.clipboardData.getData('text/plain'))
                }}
            />
        );
    };

    const boolCell = (curV, isChange, newV) => {
        if (isChange)
            return <span>
                <p className={newV ? "text-success m-0" : "text-danger mb-0"}
                    style={{ border: "1px dashed blue" }}>
                    {newV ? "Enabled" : "Disabled"}</p>
                <p className="text-warning mb-0" >Old: {curV ? "Enabled" : "Disabled"}</p>
            </span>

        return <p className={curV ? "text-success m-0" : "text-danger mb-0"}>{curV ? "Enabled" : "Disabled"}</p>
    };

    const cellFunc = (row, field_name, field) => {
        const newVal = targetDiff[row.original.id]?.[field_name]
        const hasChange = hasPending && newVal !== undefined && newVal !== null && newVal != row.value

        if (field.type == "bool") return boolCell(row.value, hasChange, newVal)

        if (hasChange) {
            return <div>
                <div style={{ border: "1px dashed blue" }}>{newVal}</div>
                <p className="text-center mb-0"><span className="text-warning">Old: {field.cell ? field.cell(row) : row.value}</span></p>
            </div>
        }
        return <div >{field.cell ? field.cell(row) : row.value}</div>
    }

    const columns = Object.keys(props.fields).map((field) => ({
        Header: props.fields[field].title,
        id: field,
        accessor: field,
        Cell: (isEdit && !props.fields[field].const) ?
            renderEditable :
            (cell => cellFunc(cell, field, props.fields[field])),
        sortMethod: props.fields[field].sortMethod
    }));

    if (isEdit) {
        columns.push({
            Header: "Delete",
            id: "delete",
            Cell: cell => <i className="fa fa-times fa-lg text-danger pointer" aria-hidden="true"
                onClick={e => onDeleteInput(cell.original)}
            />
        })
    }

    const rowClassName = (state, row) => {
        switch (true) {
            case row.original.is_new:
                return "bg-is-new";
            case row.original.is_delete:
                return "bg-is-delete";
        }
    };

    const masonryOptions = {
        transitionDuration: 0,
        percentPosition: true,
        columnWidth: props.columnWidth || 1,
        enableResizableChildren: true,
    };

    const Filter = (
        <div className="d-block d-sm-flex mobile-mb-s" style={{ width: "100%" }}>
            <Select
                className="w-25 w-sm-100 mr-3 mobile-mb-s"
                placeholder="Search integrations..."
                options={integrations.map(i => { return { value: i, label: capitalize(i) } })}
                isMulti
                onChange={set_searchIntegration}
                value={searchIntegration}
                styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            <Select
                className="w-25 w-sm-100 mr-3 mobile-mb-s"
                placeholder="Search bases..."
                options={options}
                isMulti
                onChange={set_searchBase}
                value={searchBase}
                styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            <Select
                className="w-25  w-sm-100 mr-3 mobile-mb-s"
                placeholder="Search quotes..."
                options={options}
                isMulti
                onChange={set_searchQuote}
                value={searchQuote}
                styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {
                hasPending && <Select
                    className="w-25 w-sm-100 mr-3 mobile-mb-s"
                    placeholder="Search changes..."
                    options={[
                        { value: "new", label: "New" },
                        { value: "delete", label: "Delete" },
                        { value: "change", label: "Change" },
                        { value: "unchange", label: "Unchange" },
                    ]}
                    isMulti
                    onChange={set_searchChange}
                    value={searchChange}
                />
            }
        </div>
    )

    const onchangeJsonEditor = (value) => {
        // newValue = [...jsonValue, ...noneFilteredValue]
        let noneFilteredValue = []
        if (searchIntegration?.length || searchBase?.length || searchQuote?.length) {
            const searchesI = searchIntegration.map((s) => s.value);
            const searchesB = searchBase.map((s) => s.value);
            const searchesQ = searchQuote.map((s) => s.value);
            noneFilteredValue = input?.filter(i =>
                (searchesI.length && !searchesI.includes(i.integration)) ||
                (searchesB.length && !searchesB.includes(i.base_asset_id)) ||
                (searchesQ.length && !searchesQ.includes(i.quote_asset_id))
            )
        }
        set_input([...value, ...noneFilteredValue])
    }
    return (
        <div >
            <div className="col-sm-12 p-0" style={{ height: "100vh" }}>
                <div className="panel-header">
                    <div className="panel-title text-dark p-0">
                        PAIR RFQ PARAM
                        {showEditor && <span>{backBtn} {saveBtn}
                        </span>}
                    </div>
                    <Masonry options={masonryOptions}>
                        {
                            showEditor ? (
                                <JsonEditor
                                    className="w-100" value={filterInput()} mode="code" ref={jsonEditorRef}
                                    allowedModes={["tree", "view", "form", "code", "text"]}
                                    htmlElementProps={{ style: { height: "calc(100vh - 100px)", width: "100%" } }}
                                    onChange={(value) => onchangeJsonEditor(value)}
                                />
                            ) :
                                <div className="col-sm-12 p-0 mt-3">
                                    <TableData
                                        rfqPairs
                                        searchChange
                                        isShowPaginate
                                        isShowPageSize
                                        initialPageSize={20}
                                        style={{ maxHeight: "calc(100vh - 140px)" }}
                                        data={filterInput()}
                                        buttonElements={btnElements()} columns={columns}
                                        rowClassName={rowClassName}
                                        tableFilter={Filter}
                                    />
                                </div>
                        }
                    </Masonry>
                </div>
            </div>
        </div >
    );
}

export default withAlert()(ConfigTable);
