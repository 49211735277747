import ConfigTable from "./table";
import "./style.css";
import { title } from "process";

const Integrations = () => (
    <div className="panel panel-table">
        <ConfigTable
            pendingUrl={"/v3/setting-integration"}
            fields={{
                name: {
                    title: "name",
                },
                sample_size: {
                    title: "sample size",
                },
                prices_interval_second: {
                    title: (
                        <span>
                            prices
                            <br />
                            interval sec
                        </span>
                    ),
                },
                volatility_period_second: {
                    title: (
                        <span>
                            volatility
                            <br />
                            period sec
                        </span>
                    ),
                },
                target_adjust: {
                    title: "target adjust",
                },
                weight: {
                    title: "weight",
                },
                ddof: {
                    title: "ddof",
                },
                ttl: {
                    title: "ttl",
                },
                level_volatility_factor: {
                    title: "level volatility factor",
                },
                max_trade_eth: {
                    title: "max trade eth",
                },
                price_bps_delta: {
                    title: "price bps delta",
                },
                quote_bps_delta: {
                    title: "quote bps delta",
                },
                quote_delay_millis: {
                    title: "quote delay millis",
                },
                quote_volatility_factor: {
                    title: "quote volatility factor",
                },
            }}
        />
    </div>
);

export default Integrations;
